import React from 'react'
import styles from './Advantages.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


const Advantages = ({isOpen}) => {

    return (
        <motion.section 
        className={styles.main}
        id='gruppy' 
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}><span>6 ПРИЧИН</span> ПОЧЕМУ ИМЕННО МЫ?</motion.h2>
        <motion.ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>01</p>
                            <p className={styles.item_title}>Собственный автопарк</p>
                        </div>
                        <p className={styles.item_text}>Наша компания располагает собственным автопарком современных самосвалов и специальной техники.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>02</p>
                            <p className={styles.item_title}>Работаем 24/7</p>
                        </div>
                        <p className={styles.item_text}>Заказывайте необходимые материалы  или услуги в любое удобное время, мы осуществляем поставки и предоставление услуг круглосуточно.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>03</p>
                            <p className={styles.item_title}>Всегда в наличии</p>
                        </div>
                        <p className={styles.item_text}>Любой объем необходимых вам материалов доступен для загрузки и поставки с наших складов расположенных в Московской области.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>04</p>
                            <p className={styles.item_title}>Быстрая доставка</p>
                        </div>
                        <p className={styles.item_text}>Стандарты работы нашей компании, исключают откладывание заявок на вторую очередь. Мы оперативно доставим заказанные материалы или вывезем мусор и снег.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>05</p>
                            <p className={styles.item_title}>Гарантия качества</p>
                        </div>
                        <p className={styles.item_text}>Наша компания гарантирует высокое качество песка, щебня и грунта. Все материалы соответствуют государственным стандартам.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>06</p>
                            <p className={styles.item_title}>Удобный расчет</p>
                        </div>
                        <p className={styles.item_text}>Работаем за наличные и безналичный расчет, НДС. Заключаем договора с компаниями на разовые и постоянные услуги.</p>
                    </motion.li>
                </motion.ul>
        </div>   
    </motion.section>
    )
}

export default Advantages