import React from 'react'
import styles from './Services.module.scss'
import { motion } from "framer-motion"
import icon_1 from '../../image/Services/img_1.jpg'
import icon_2 from '../../image/Services/img_2.jpg'
import icon_3 from '../../image/Services/img_3.webp'
import icon_4 from '../../image/Services/img_4.jpg'
import icon_5 from '../../image/Services/img_5.jpg'
import icon_6 from '../../image/Services/img_6.jpg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3}
    } 
}

const Services = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.02, once: true}}
            >
                <div className={styles.box}>
                    <motion.h2 className={styles.title} variants={titleAnimation}><span>материалы и услуги</span> которые мы&#160;предлагаем</motion.h2>
                    <motion.ul className={styles.list}>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_1} alt='илосос'/>
                            <div className={styles.box_info}>
                                <div className={styles.info}>
                                    <h3 className={styles.item_title}>Щебень</h3>
                                    <p className={styles.item_price}>вторичный <span>от 1 800 ₽</span></p>
                                    <p className={styles.item_price}>гранитный <span>от 4 500 ₽</span></p>
                                    <p className={styles.item_price}>гравийный <span>от 3 800 ₽</span></p>
                                    <p className={styles.item_price}>известняковый <span>от 3 100 ₽</span></p>
                                    <p className={styles.item_price}>гранитный отсев <span>от 2 250 ₽</span></p>
                                </div>
                                <button className={styles.button}  type='button' onClick={isOpen}>ЗАКАЗАТЬ <span className={styles.glare}></span></button>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_2} alt='илосос'/>
                            <div className={styles.box_info}>
                                <div className={styles.info}>
                                    <h3 className={styles.item_title}>Плодородный грунт</h3>
                                    <p className={styles.item_price}>почвогрунт <span>от 1 500 ₽</span></p>
                                    <p className={styles.item_price}>торф <span>от 1 300 ₽</span></p>
                                    <p className={styles.item_price}>чернозем <span>от 1 700 ₽</span></p>
                                </div>
                                <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ <span className={styles.glare}></span></button>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_3} alt='илосос'/>
                            <div className={styles.box_info}>
                                <div className={styles.info}>
                                    <h3 className={styles.item_title}>Строительный песок</h3>
                                    <p className={styles.item_price}>карьерный песок <span>от 950 ₽</span></p>
                                    <p className={styles.item_price}>Сеянный песок <span>от 1 300 ₽</span></p>
                                </div>
                                <button className={styles.button} type='button' onClick={isOpen}>ЗАКАЗАТЬ <span className={styles.glare}></span></button>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_4} alt='илосос'/>
                            <div className={styles.box_info}>
                                <div className={styles.info}>
                                    <h3 className={styles.item_title}>Асфальтовая крошка</h3>
                                    <p className={styles.item_price}>крошка <span>от 1 700 ₽</span></p>
                                </div>
                                <button className={styles.button} type='button' onClick={isOpen} >ЗАКАЗАТЬ <span className={styles.glare}></span></button>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_5} alt='илосос'/>
                            <div className={styles.box_info}>
                                <div className={styles.info}>
                                    <h3 className={styles.item_title}>Вывоз</h3>
                                    <p className={styles.item_price}>грунта <span>от 500 ₽</span></p>
                                    <p className={styles.item_price}>снега <span>от 500 ₽</span></p>
                                    <p className={styles.item_price}>строительного мусора <span>от 1 000 ₽</span></p>
                                </div>
                                <button className={styles.button} type='button' onClick={isOpen} >ЗАКАЗАТЬ <span className={styles.glare}></span></button>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_6} alt='илосос'/>
                            <div className={styles.box_info}>
                                <div className={styles.info}>
                                    <h3 className={styles.item_title}>Транспортировка</h3>
                                    <p className={styles.item_price}>грунта <span>от 500 ₽</span></p>
                                    <p className={styles.item_price}>песка <span>от 500 ₽</span></p>
                                    <p className={styles.item_price}>щебня <span>от 500 ₽</span></p>
                                </div>
                                <button className={styles.button} type='button' onClick={isOpen} >ЗАКАЗАТЬ <span className={styles.glare}></span></button>
                            </div>
                        </motion.li>
                    </motion.ul>
                </div>
        </motion.section>
        
    )
}

export default Services