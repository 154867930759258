import React from 'react'
import styles from './DetailMap.module.scss'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'
import nebo from '../../image/DetailMap/map-icon.svg'

const DetailMap = () => {


    return (
        <div className={styles.main}>
            <div className={styles.box_map}>
                    <YMaps>
                        <Map width='100%' height='100%' defaultState={{ center: [55.804810, 37.566243], zoom: 16}}>
                            <Placemark 
                                geometry={[55.804810, 37.566243]} 
                                    options={{
                                        iconLayout: 'default#image',
                                        iconImageSize: [50, 60],
                                        iconImageHref: nebo,
                                        }}
                                    />
                        </Map>
                    </YMaps>
            </div>

        </div>
    )
}

export default DetailMap