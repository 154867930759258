import React, {useCallback, useState} from 'react'
import app from './App.module.scss'
import Main from '../../pages/Main/Main'
import Header  from '../Header/Header'
import Footer  from '../Footer/Footer'
import Modal from '../Modal/Modal'
import ModalForm  from '../ModalForm/ModalForm'
import ButtonCommunication from '../ButtonCommunication/ButtonCommunication'


import UpButton from '../UpButton/UpButton'

function App() {

  const [isPopupFormOpen, setIsPopupFormOpen] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const vh = window.innerHeight / 100;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  const handleFormOpen = useCallback(() => {
    setIsPopupFormOpen(true)
  }, []);

  const handleFormClose = useCallback(() => {
    setIsPopupFormOpen(false)
  }, []);

  const handleOpen = useCallback(() => {
    setIsPopupOpen(true)
  }, []);

  const handleClose = useCallback(() => {
    setIsPopupOpen(false)
  }, []);



  return (
    <div className={app.page}>
      <Header
        isOpen={handleFormOpen}
      />
      <Main
        isOpen={handleFormOpen}
        popupOpen={handleOpen}
      />
      <Footer/>
      <UpButton/>
      <ButtonCommunication/>
      {isPopupFormOpen && <ModalForm
        onClose={handleFormClose}
        popupOpen={handleOpen}
      ></ModalForm>}
      {isPopupOpen && 
      <Modal
        onClose={handleClose}
      />}
    </div>
  );
}

export default App;
