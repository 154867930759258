import React from 'react'
import { motion } from "framer-motion"
import styles from './Feedback.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Autoplay} from "swiper"
import "./Feedback.css"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"
import img from '../../image/Feedback/img.webp'
import Annotation from '../Annotation/Annotation'

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}


const Feedback = () => {

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <motion.div className={styles.box} variants={boxAnimation}>
                <h2 className={styles.title}>ОТЗЫВЫ НАШИХ КЛИЕНТОВ</h2>
                <Annotation/>
                    <div className={styles.slider}>
                        <Swiper
                                spaceBetween={10}
                                slidesPerView={1.3}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    type: 'fraction'
                                }}
                                breakpoints={{

                                    1400: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 5,
                                    },

                                    768: {
                                        slidesPerView: 1.2,
                                        spaceBetween: 5,
                                    },

                                    425: {
                                        slidesPerView: 1,
                                        spaceBetween: 5,
                                    },

                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 5,
                                    },
                                }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper"
                                >
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Геннадий Симонов</p>
                                        <p className={styles.comment}>14.02.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Надёжная компании в части поставок стройматериалов. В основном закупаем у них щебенку и песок, а при необходимости и другие материалы. В отличии от предыдущего поставщика за качество отвечают, контролируют доставку, не завышают цены.</p>
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Roman</p>
                                        <p className={styles.comment}>27.06.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Помогли мне с выбором песка для строительства дачного участка, проконсультировали и подобрали песок с оптимальным коэффициентом фильтрации. Порадовали выгодные цены и быстрая доставка.</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Дмитрий</p>
                                        <p className={styles.comment}>23.02.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Заказывал песок, к объёму претензий нет, водитель молодец, аккуратно заехал в очень сложное место. Весь процесс от заказа до получения без лишней бюрократии.</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Алексей</p>
                                        <p className={styles.comment}>01.02.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Приятно был удивлён обслуживанием компании, в плане цена/качество. Вовремя связались со мной, помогли сформировать заказ. Щебень привезли уже на следующий день после моего заказа.</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Анна Ледовская</p>
                                        <p className={styles.comment}>27.03.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Довольно оперативно отреагировали на нашу заявку и удалось согласовать ближайшую дату. Со своей стороны мы все подготовили для скорой погрузки мусора. Компания зарекомендовала себя с надеждой стороны. Машина прибыла вовремя. Удалось вывезти весь объем нашего мусора.</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>           
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Vlad</p>
                                        <p className={styles.comment}>18.01.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Не обманули, грунт действительно хороший, рассыпчатый и, что самое главное - в меру влажный. Нет никаких посторонних примесей и мусора, что тоже крайне важно. Вполне доступная стоимость и самое главное быстрая доставка, мне на участок привезли уже вечером в день заказа. </p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Маргарита К.</p>
                                        <p className={styles.comment}>15.12.2022</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Ответственно работают, водитель приехал в нужное время и правильно припарковался для разгрузки, аккуратно высыпал щебень, не повредив ничего, хотя мы очень боялись за забор и растительность.</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79256067070' aria-label='связаться вацап'>
                        <span>Задать свой вопрос в Whatsapp</span>
                        <svg  xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.92327 0.277344C4.59796 0.277344 0.2771 4.5982 0.2771 9.92351C0.2771 12.0559 0.967314 14.0199 2.13451 15.6192L0.905588 19.2554L4.73824 18.0602C6.23651 19.0141 8.01536 19.5697 9.92327 19.5697C15.2486 19.5697 19.5694 15.2488 19.5694 9.92351C19.5694 4.5982 15.2486 0.277344 9.92327 0.277344ZM14.3395 15.0973C12.5887 15.7988 10.5013 15.1198 8.21737 13.3858C5.93349 11.6519 4.42961 9.21085 4.3286 7.39834C4.2276 5.58582 5.70342 4.67115 6.50587 4.67676C7.30831 4.68237 8.18932 7.24683 8.20054 7.63963C8.21176 8.03805 7.21853 8.7451 7.17925 9.08179C7.13997 9.41848 8.53162 11.0795 9.12083 11.5284C9.70442 11.9717 10.9109 12.8247 11.5338 12.7461C12.151 12.6619 12.5607 11.4218 12.9535 11.3432C13.3463 11.2647 15.4899 12.3308 15.7031 12.6058C15.9164 12.8864 15.8939 14.4801 14.3395 15.0973Z"></path>
                        </svg>
                    </a>         
                </motion.div>
        </motion.section>
    )
}

export default Feedback